import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import ImmutablePropTypes from "react-immutable-proptypes";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";

import { loadSubscriptions } from "Reducers/subscription";
import BillDetail from "Containers/BillDetail";
import SettingLine from "Components/SettingLine";
import Dropdown from "Components/Dropdown";

const Layout = styled.div`
  .header-arrow + .header-arrow {
    border-top: 1px solid #d8e2ef;
  }
  .filter-plan {
    position: absolute;
    right: 0;
    top: -5px;
    .input-wrapper {
      width: 132px;
    }
    .select-box__control {
      background: transparent;
    }
  }
`;

const InfoLayout = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  font-size: 15px;
  color: #4b6180;
  strong {
    font-weight: 600;
  }
  .project {
    width: 262px;
    font-size: 16px;
    color: #38485e;
    strong {
      font-weight: 700;
    }
  }
  .plan {
    display: inline-block;
    &:first-letter {
      text-transform: uppercase;
    }
  }
  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 16px;
  }
`;

// @todo: move to constants file.
const planTypesMap = {
  "": "All plans",
  development: "Development",
  standard: "Standard",
  medium: "Medium",
  large: "Large",
  xlarge: "Xlarge",
  "2xlarge": "2xlarge"
};
const planTypes = Object.keys(planTypesMap).map(item => ({
  value: item,
  label: planTypesMap[item]
}));

class PlanListField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openIndex: "",
      filterPlan: "",
      total: ""
    };
    this.expand = this.expand.bind(this);
    this.cancel = this.cancel.bind(this);
    this.selectFilter = this.selectFilter.bind(this);
    this.filterSubscriptions = this.filterSubscriptions.bind(this);
    this.filterByType = this.filterByType.bind(this);
  }

  componentWillMount() {
    this.props.loadSubscriptions();
  }

  expand(index) {
    if (index === this.state.openIndex) {
      this.cancel();
    } else {
      this.setState({
        openIndex: index
      });
      this.loadEstimate(index);
    }
  }

  async loadEstimate(subscriptionId) {
    const subscription = this.props.subscriptions.get(subscriptionId);
    const { total } = await subscription.getEstimate();
    this.setState({ total });
  }

  cancel() {
    this.setState({
      openIndex: ""
    });
  }

  selectFilter(e) {
    this.setState({
      filterPlan: e.value
    });
  }

  filterSubscriptions(item) {
    if (item.status === "provisioning failure") {
      return false;
    }
    let title = item.project_title;
    if (!title) {
      title = "Untitled Project";
    }

    if (!this.props.titleFilter) {
      return true;
    }

    return title.toUpperCase().includes(this.props.titleFilter.toUpperCase());
  }

  filterByType(item) {
    if (!this.state.filterPlan || item.plan === this.state.filterPlan) {
      return true;
    }
    return false;
  }

  render() {
    const { organizationId, subscriptions, intl } = this.props;

    return (
      <Layout>
        <Dropdown
          className="filter-plan outlined"
          onChange={e => this.selectFilter(e)}
          options={planTypes}
          defaultText="All plans"
          defaultValue={{ value: "", label: "All plans" }}
        />
        {subscriptions &&
          subscriptions
            .valueSeq()
            .filter(this.filterSubscriptions)
            .filter(this.filterByType)
            .map(bill => {
              return (
                <SettingLine
                  key={bill.id}
                  info={
                    <InfoLayout>
                      <span className="project">
                        <strong>
                          {bill.project_title
                            ? bill.project_title
                            : "Untitled Project"}
                        </strong>
                      </span>
                      <span className="plan col-2">{bill.plan}</span>
                      <span className="environments">
                        <strong>{bill.environments}</strong> Enviroments
                      </span>
                      <span className="storage">
                        <strong>{bill.storage / 1024} GB</strong> Storage
                      </span>
                      <span className="users">
                        <strong>{bill.user_licenses}</strong> User
                      </span>
                      <span className="owner col-2">
                        {bill.owner_info.display_name}
                      </span>
                    </InfoLayout>
                  }
                  isOpen={this.state.openIndex === bill.id}
                  openText={intl.formatMessage({ id: "view" })}
                  onClick={() => this.expand(bill.id)}
                >
                  {this.state.openIndex &&
                    this.state.openIndex === bill.id && (
                      <BillDetail
                        organizationId={organizationId}
                        bill={bill}
                        deleteSubscription={this.props.deleteSubscription}
                        total={this.state.total}
                      />
                    )}
                </SettingLine>
              );
            })}
      </Layout>
    );
  }
}

PlanListField.propTypes = {
  organizationId: PropTypes.string,
  bills: PropTypes.object,
  loadSubscriptions: PropTypes.func,
  subscriptions: PropTypes.oneOfType([
    ImmutablePropTypes.map,
    ImmutablePropTypes.list
  ]),
  intl: PropTypes.object,
  titleFilter: PropTypes.string,
  deleteSubscription: PropTypes.func
};

const mapStateToProps = (state, props) => {
  const subscriptions = state.subscription || new Map();

  return {
    subscriptions: subscriptions.getIn(["data", props.organizationId])
  };
};

const mapDispatchToProps = (dispatch, props) => ({
  loadSubscriptions: () => dispatch(loadSubscriptions(props.organizationId)),
  deleteSubscription: subscription =>
    import("Reducers/subscription").then(reducer => {
      dispatch(reducer.deleteSubscription(subscription));
    })
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(PlanListField));
