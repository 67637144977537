import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Select from "react-select";

import Error from "Components/Error";
import Label from "Components/fields/Label";
import Chevron from "Components/icons/Chevron";
import RequiredTag from "Components/fields/RequiredTag";

const DropdownLayout = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  align-items: flex-start;
  .input-wrapper {
    flex-direction: column;
    align-items: flex-start;
  }
  .select-box {
    width: 264px;
  }
  .select-box,
  .select-box__control,
  .select-box__value-container {
    height: 32px;
  }
  .select-box__control {
    background: #f5f7fa;
    border: 1px solid #f5f7fa;
    border-radius: 2px;
    min-height: 32px;
  }
  .select-box__placeholder {
    font-size: 15px;
    color: #38485E;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
    padding-right: 10px;
    max-width: 100%;
    &:first-letter {
      text-transform: uppercase;
    }
  }
  .select-box:hover {
    .select-box__control {
      background: #f5f7fa;
      border: 1px solid #f5f7fa;
    }
    .select-box__single-value,
    .select-box__placeholder,
    .select-box__dropdown-indicator svg {
      color: ${props => props.theme.links}
    }
  }
  .select-box__value-container {
    padding: 0 16px;
    .select-box__single-value {
      height: 32px;
      line-height: 30px;
    }
  }
  .select-box__indicator-separator {
    display: none;
  }
  .select-box__dropdown-indicator {
    padding: 0 6px 0 0;
    svg {
      color: #4a495e;
    }
  }
  .select-box__control--is-focused {
    border: 1px solid ${props => props.theme.buttonHover} !important;
    box-shadow: 0 0 2px ${props => props.theme.buttonHover};
    outline: none;
    .select-box__dropdown-indicator svg {
      transform: rotate(180deg);
    }
  }
  .select-box__control--is-disabled {
    cursor: not-allowed;
    border: none;
    .select-box__indicators {
      display: none;
    }
  }
  .select-box__single-value {
    font-size: 15px;
    margin: 0;
    &:first-letter {
      text-transform: uppercase;
    }
  }
  .select-box__menu {
    border-radius: 2px;
    background: #fff;
    border: none;
    box-shadow: 0 6px 24px rgba(152, 160, 171, 0.4);
    .select-box__menu-list {
      padding: 8px;
    }
    .select-box__option {
      line-height: 32px;
      height: 32px;
      color: #38485E;
      padding: 0 8px;
      border-radius: 4px;
      font-size: 15px;
      white-space: nowrap
      overflow: hidden;
      text-overflow: ellipsis;
      &:first-letter {
        text-transform: uppercase;
      }
      &.select-box__option--is-focused:not(:hover):not(:active) {
        border: 1px solid ${props => props.theme.buttonHover};
        box-shadow: 0 0 2px ${props => props.theme.buttonHover};
        background: transparent;
        margin: 0 -1px;
        line-height: 30px;
      }
      &:hover {
        background: ${props => props.theme.menuHover};
      }
      &:active {
        background: transparent;
        color: ${props => props.theme.links}
      }
    }
    .select-box__option--is-selected {
      background: transparent;
      color: ${props => props.theme.links};
    }
  }
  .required-message {
    display: inline-block;
    color: #ec4646;
    font-size: 13px;
    &:first-letter {
      text-transform: uppercase;
    }
  }
  &.outlined {
    .select-box {
      width: 132px;
    }
    .select-box__control,
    .select-box:hover  .select-box__control {
      cursor: hover;
      border: 1px solid #C9D0E4;
      border-radius: 4px;
      background: #fff;
      &:focus {

      }
    }
  }
  &.is-disabled {
    cursor: not-allowed;
    label {
      opacity: 0.5;
    }
  }
  &.field-error {
    label,
    .required-message {
      color: #ec4646;
    }
    .required-notice {
      background: #ec4646;
    }
    .select-box__control {
      border: 1px solid #ec4646;
      margin: -1px;
    }
  }
  &.inline {
    .input-wrapper {
      flex-direction: row;
    }
  }
`;

const InputLayout = styled.div`
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  align-items: center;
  width: 264px;
  position: relative;
  &.stacked {
    flex-direction: column;
    align-items: flex-start;
    .Select,
    .Select-control,
    .Select-menu-outer {
      width: 100% !important;
    }
    .Select {
      background: #f7f7f7;
    }
  }
  .Select-input:focus {
    background: transparent;
  }
`;

class Dropdown extends React.Component {
  constructor(props) {
    super(props);
    this.arrowRenderer = this.arrowRenderer.bind(this);
    this.getIndex = this.getIndex.bind(this);
    this.isRequired = this.isRequired.bind(this);
    this.state = {
      isRequire: false
    };
  }
  isRequired(required, value) {
    required && !value
      ? this.setState({
          isRequired: true
        })
      : this.setState({
          isRequired: false
        });
  }
  getIndex(array, value) {
    return array.findIndex(i => (i.value = value));
  }
  arrowRenderer() {
    return <Chevron />;
  }
  render() {
    return (
      <DropdownLayout
        className={`dropdown${
          this.props.className ? " " + this.props.className : ""
        }${this.props.disabled ? " is-disabled" : ""}${
          this.state.isRequired &&
          !this.props.isChanged &&
          !this.props.defaultValue
            ? " field-error"
            : ""
        }`}
      >
        <InputLayout
          id={this.props.label && this.props.label.replace(" ", "-")}
          className="input-wrapper"
        >
          {this.props.label && (
            <Label
              htmlFor={`${this.props.label &&
                this.props.label.replace(" ", "-")}-options`}
            >
              {this.props.label}
              {this.props.required ? <RequiredTag /> : ""}
            </Label>
          )}
          <Select
            {...this.props}
            inputProps={{
              id: `${this.props.label &&
                this.props.label.replace(" ", "-")}-options`
            }}
            tabIndex="0"
            aria-label={this.props.label}
            arrowRenderer={this.arrowRenderer}
            className="select-box"
            classNamePrefix="select-box"
            defaultValue={this.props.defaultValue}
            placeholder={
              this.props && this.props.defaultText
                ? this.props.defaultText
                : this.props && this.props.value
                  ? this.props.options[
                      this.getIndex(this.props.options, this.props.value)
                    ].label
                  : "Select..."
            }
            isSearchable={false}
            onChange={e => this.props.onChange(e)}
            controlShouldRenderValue={true}
            required={this.props.required}
            isDisabled={this.props.disabled}
            onBlur={() => {
              this.isRequired(this.props.required, this.props.defaultValue);
            }}
          />
          {this.state.isRequired && (
            <div className="required-message">
              {this.props.label} is required.
            </div>
          )}
        </InputLayout>
        {this.props.error && <Error>{this.props.error}</Error>}
      </DropdownLayout>
    );
  }
}

Dropdown.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  style: PropTypes.object,
  error: PropTypes.string,
  name: PropTypes.string,
  defaultValue: PropTypes.object,
  defaultText: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  onChange: PropTypes.func,
  isChanged: PropTypes.bool,
  value: PropTypes.string,
  options: PropTypes.array
};

export default Dropdown;
