import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import { withRouter } from "react-router";

import Label from "Components/fields/Label";
import ButtonWrapper from "Components/ButtonWrapper";
import Button from "Components/Button";
import InfoDialog from "Components/InfoDialog";
import ModalConfirmDelete from "Components/ModalConfirmDelete";

const InfoLayout = styled.div`
  width: 100%;
  padding: 32px;
  box-sizing: border-box;
`;

const ColumnWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 32px;
`;

const Column = styled.div`
  width: 30%;
  label {
    display: inline-block;
    width: 88px;
    margin-right: 22px;
    padding-top: 2px;
  }
  &.total-column {
    display: flex;
    justify-content: flex-end;
  }
`;

const ItemGroup = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 22px;
  font-size: 15px;
  line-height: 20px;
  .plan {
    &:first-letter {
      text-transform: uppercase;
    }
  }
  &:last-child {
    margin-bottom: 0;
  }
`;

const Total = styled.div`
  background: #f5f7fa;
  line-height: 40px;
  height: 40px;
  padding: 0 16px;
  color: #38485e;
  font-size: 14px;
  width: 264px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  span {
    display: flex;
    align-items: center;
  }
  .amount {
    font-weight: 600;
    font-size: 16px;
  }
`;

class BillDetail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      openModal: false
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  onNameChange(e) {
    const name = e.target.value;

    this.setState({
      name
    });
  }

  save() {
    const name = this.state.name;

    this.props.updateTeam({ name });
  }

  editName() {
    this.setState({
      editedName: true
    });
  }

  cancel() {
    this.setState({
      editedName: false
    });
  }

  saveName() {
    this.setState({
      editedName: false
    });
  }

  openModal() {
    this.setState({
      openModal: true
    });
  }

  closeModal() {
    this.setState({
      openModal: false
    });
  }

  render() {
    const { bill } = this.props;

    return (
      <InfoLayout>
        <ColumnWrapper>
          <Column>
            <ItemGroup>
              <Label>Plan type</Label>
              <div className="plan">{bill.plan}</div>
            </ItemGroup>
            <ItemGroup>
              <Label>Included</Label>
              <div>
                <div>5 GB Disk space</div>
                <div>3 Development environments</div>
                {bill.plan !== "development" && (
                  <div>1 Production environment</div>
                )}
                <div>1 User</div>
              </div>
            </ItemGroup>
          </Column>
          <Column>
            <ItemGroup>
              <Label>Environments</Label>
              <div>{bill.environments}</div>
            </ItemGroup>
            <ItemGroup>
              <Label>Storage</Label>
              <div>{bill.storage / 1024} GB</div>
            </ItemGroup>
            <ItemGroup>
              <Label>Users</Label>
              <div>
                {bill.user_licenses > 1 ? bill.user_licenses - 1 : 0} additional
              </div>
            </ItemGroup>
          </Column>
          <Column className="total-column">
            <Total className="total">
              <span>
                Total monthly cost*{" "}
                <InfoDialog
                  title="Estimated cost"
                  text="Billing is based on actual usage at the end of each month. Prices displayed include VAT when applicable."
                />
              </span>{" "}
              <span className="amount">{this.props.total}</span>
            </Total>
          </Column>
        </ColumnWrapper>
        <ButtonWrapper>
          <Button
            onClick={() => {
              this.props.router.push(
                "/" +
                  this.props.organizationId +
                  "/" +
                  bill.project_id +
                  "/plan"
              );
            }}
          >
            Upgrade plan
          </Button>
          <Button className="outline" onClick={this.openModal}>
            Delete project
          </Button>
        </ButtonWrapper>
        <ModalConfirmDelete
          title="Delete project"
          deleteFunction={() => this.props.deleteSubscription(bill)}
          isOpen={this.state.openModal}
          closeModal={this.closeModal}
          itemId={`subscription-${bill && bill.id}`}
          size="medium"
        >
          <p>
            Are you sure you want to delete your project{" "}
            <strong>{bill && bill.project_title}</strong>
          </p>
          <p>
            Please note that deleting your project is irreversible and all data
            associated with this project will be deleted, including backups.
            Lastly, at the end of the month you will be charged for any
            remaining project costs.
          </p>
        </ModalConfirmDelete>
      </InfoLayout>
    );
  }
}

BillDetail.propTypes = {
  bill: PropTypes.object,
  intl: PropTypes.object,
  organizationId: PropTypes.string,
  updateTeam: PropTypes.func,
  router: PropTypes.shape({
    push: PropTypes.func.isRequired
  }),
  deleteSubscription: PropTypes.func,
  total: PropTypes.string
};

export default injectIntl(withRouter(BillDetail));
