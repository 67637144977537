import { fromJS, Map } from "immutable";

// import config from "Constants/api_config";
import logger from "Libs/logger";

const LOAD_SUBSCRIPTIONS_START = "app/subscriptions/load_start";
const LOAD_SUBSCRIPTIONS_SUCCESS = "app/subscriptions/load_success";
const LOAD_SUBSCRIPTIONS_FAILURE = "app/subscriptions/load_failure";

const LOAD_SUBSCRIPTION_START = "app/subscription/load_start";
const LOAD_SUBSCRIPTION_SUCCESS = "app/subscription/load_success";
const LOAD_SUBSCRIPTION_FAILURE = "app/subscription/load_failure";

const UPDATE_SUBSCRIPTION_START = "app/subscription/update_start";
const UPDATE_SUBSCRIPTION_SUCCESS = "app/subscription/update_success";
const UPDATE_SUBSCRIPTION_FAILURE = "app/subscription/update_failure";

const DELETE_SUBSCRIPTION_START = "app/subscription/delete_start";
const DELETE_SUBSCRIPTION_SUCCESS = "app/subscription/delete_success";
const DELETE_SUBSCRIPTION_FAILURE = "app/subscription/delete_failure";

export const loadSubscriptions = organizationId => {
  return async dispatch => {
    dispatch({ type: LOAD_SUBSCRIPTIONS_START });
    try {
      // Load the current user
      const platformLib = await import("Libs/platform");
      const client = platformLib.default;
      const subscriptions = await client.getSubscriptions();

      dispatch({
        type: LOAD_SUBSCRIPTIONS_SUCCESS,
        payload: {
          subscriptions
        },
        meta: { organizationId }
      });
    } catch (err) {
      logger(err, {
        action: LOAD_SUBSCRIPTIONS_FAILURE
      });
      dispatch({ type: LOAD_SUBSCRIPTIONS_FAILURE, error: true, payload: err });
    }
  };
};

export const loadSubscription = id => {
  return async dispatch => {
    dispatch({ type: LOAD_SUBSCRIPTION_START });
    try {
      // Load the current user
      const platformLib = await import("Libs/platform");
      const client = platformLib.default;
      const subscription = await client.getSubscription(id);

      dispatch({
        type: LOAD_SUBSCRIPTION_SUCCESS,
        payload: {
          subscription
        },
        meta: {
          projectId: subscription.project_id,
          userName: subscription.owner_info.username
        }
      });
    } catch (err) {
      logger(err, {
        action: LOAD_SUBSCRIPTION_FAILURE
      });
      dispatch({ type: LOAD_SUBSCRIPTIONS_FAILURE, error: true, payload: err });
    }
  };
};

export const updateSubscription = (updates, subscription) => {
  return async dispatch => {
    // Set the thing as loading = true
    dispatch({ type: UPDATE_SUBSCRIPTION_START });
    try {
      const updatedSubscription = await subscription.update({ ...updates });

      dispatch({
        type: UPDATE_SUBSCRIPTION_SUCCESS,
        payload: {
          updateSubscription
        },
        meta: {
          projectId: updatedSubscription.project_id,
          userName: updatedSubscription.data.owner_info.username
        }
      });
    } catch (err) {
      logger(err, {
        action: UPDATE_SUBSCRIPTION_FAILURE
      });
      dispatch({ type: LOAD_SUBSCRIPTIONS_FAILURE, error: true, payload: err });
    }
  };
};

export const deleteSubscription = subscription => {
  return async dispatch => {
    dispatch({ type: DELETE_SUBSCRIPTION_START });
    try {
      subscription.delete().then(() => {
        dispatch({
          type: DELETE_SUBSCRIPTION_SUCCESS,
          payload: {
            subscription
          },
          meta: {
            projectId: subscription.project_id,
            userName: subscription.owner_info.username
          }
        });
      });
    } catch (err) {
      logger(err, {
        action: DELETE_SUBSCRIPTION_SUCCESS
      });
      dispatch({
        type: DELETE_SUBSCRIPTION_FAILURE,
        error: true,
        payload: err
      });
    }
  };
};

export default function subscriptionReducer(state = new Map(), action) {
  switch (action.type) {
    case LOAD_SUBSCRIPTIONS_START:
    case LOAD_SUBSCRIPTION_START:
    case UPDATE_SUBSCRIPTION_START:
    case DELETE_SUBSCRIPTION_START:
      return state.set("loading", true);
    case LOAD_SUBSCRIPTIONS_SUCCESS:
      return action.payload.subscriptions
        .reduce((subscriptionsToStore, subscription) => {
          return subscriptionsToStore.setIn(
            ["data", action.meta.organizationId, subscription.id],
            subscription
          );
        }, state)
        .set("loading", false);
    case LOAD_SUBSCRIPTION_SUCCESS:
      return state
        .setIn(
          ["data", action.meta.userName, action.meta.projectId],
          fromJS(action.payload.subscription)
        )
        .set("loading", false);
    case UPDATE_SUBSCRIPTION_SUCCESS:
      return state
        .setIn(
          ["data", action.meta.userName, action.meta.projectId],
          fromJS(action.payload.subscription)
        )
        .set("loading", false);
    case DELETE_SUBSCRIPTION_SUCCESS:
      return state
        .set("deleted", action.payload.subscription)
        .removeIn(["data", action.meta.userName, action.meta.projectId]);
    case LOAD_SUBSCRIPTIONS_FAILURE:
    case LOAD_SUBSCRIPTION_FAILURE:
    case UPDATE_SUBSCRIPTION_FAILURE:
    case DELETE_SUBSCRIPTION_FAILURE:
      return state.set("loading", false).set("error", action.payload);
    default:
      return state;
  }
}
